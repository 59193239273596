.u-format-group {
  margin-top: 60px;
  margin-bottom: 60px;
  &__title {
    max-width: $grid-row-width;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 24px;
    font-weight: bold;
    font-size: rem-calc(32);
    background: $color-primary;
    color: #fff;
    padding-left: 24px;
  }
  &__content {}
}

.u-format {
  &__title{
    font-size: rem-calc(20);
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-row-width;
    margin-top: 120px;
    margin-bottom: 18px;
    background: #f5f5f5;
    padding: 8px 12px 8px 24px;
    border-left: 5px solid $color-primary;
  }
  &__content{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-row-width;
    &.is-fullwidth {
      max-width: 100%;
    }
  }
  &__code {
    max-width: $grid-row-width;
    margin-left: auto;
    margin-right: auto;
    background: #f5f5f5;
    position: relative;
    pre {
      padding-top: 40px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      font-family: Courier;
      font-size: rem-calc(12);
    }
    &__title {
      background: #333;
      color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      padding: 4px 8px;
      font-size: rem-calc(12);
    }
  }
}
