/*
---
name: ページヘッダー_通常
category: Layout
---
*/
/*
---
name: ページヘッダー_単色
category: Layout
---
*/
@use "sass:math";

.l-page-header {
  position: relative;

  &__image {
    @include bg-option;
    width: calc(1263/1440*100%);
    height: rem-calc(400);
    border-radius: 0px 20px 20px 0px;

    @include breakpoint(medium down) {
      width: calc(100% - 32px);
      height: rem-calc(300);
    }

    @include breakpoint(small only) {
      width: calc(100% - 16px);
      height: rem-calc(200);
    }
  }

  &__inner {
    z-index: 10;
    margin-top: rem-calc(-40);

    @include breakpoint(medium down) {
      margin-top: rem-calc(-30);
    }

    @include breakpoint(small only) {
      margin-top: rem-calc(-20);
    }
  }

  &__title {
    margin: 0;

    b {
      display: block;
      @include webfont();
      @include font-format(100,0,100,600);

      @include breakpoint(medium down) {
        font-size: rem-calc(80);
      }

      @include breakpoint(small only) {
        font-size: rem-calc(56);
      }
    }

    span {
      display: block;
      @include font-format(32,0.16,32,600);
      margin-top: rem-calc(10);

      @include breakpoint(small only) {
        font-size: rem-calc(24);
        margin-top: rem-calc(5);
      }
    }
  }
}
