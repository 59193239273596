/*
---
name: ボックス_通常
category: Components
---
*/

.c-box {
  &__block {
    background-color: $color-secondary;
    padding: rem-calc(32) rem-calc(40);
    margin-bottom: rem-calc(56);
    @include breakpoint(small only) {
      padding: rem-calc(24);
      margin-bottom: rem-calc(24);

    }
    //*最後は矢印なし
    &:last-child {
      margin-bottom: 0;
      &::after {
        display: none !important;
      }
    }
  }
  &.is-number-left {
    .c-box__block {
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 32px 33px 0 33px;
        border-color: $color-primary transparent transparent transparent;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%,100%);
        @include breakpoint(small only) {
          border-width: 16px 16px 0 16px;
        }
      }
    }
    .c-box__number {
      background-color: $color-primary;
      color: $color-white;
      width: rem-calc(86);
      height: rem-calc(86);
      text-align: center;
      @include webfont();
      position: absolute;
      top: 0;
      line-height: 1;
      left: 0;
      padding-top: rem-calc(14);
      @include breakpoint(small only) {
        width: rem-calc(64);
        height: rem-calc(64);
        padding-top: rem-calc(10);
      }
      small {
        @include webfont();
        font-size: rem-calc(18);
        letter-spacing: 0.1em;
        display: block;
        @include breakpoint(small only) {
          font-size: rem-calc(14);
        }
      }
      span {
        font-size: rem-calc(42);
        letter-spacing: 0;
        display: block;
        @include breakpoint(small only) {
          font-size: rem-calc(32);
        }
      }
    }
  }
  &__image {
    @include breakpoint(small only) {
      margin-bottom: rem-calc(16);
    }
  }

  //丸画像
  &.is-roundimg {

    .c-box__image {
      width: rem-calc(200);
      height: rem-calc(200);
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      @include center-block();
      img {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        @include center-both();
      }
    }

  }
}
