/*
---
name: SNSボタン
category: Other
---
*/

.c-sns-btns {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    width: rem-calc(44);
    margin-right: rem-calc(20);
    transition: all .3s;
    @include breakpoint(small only) {
      margin-right: rem-calc(8);
    }
  }
}
