// エディタ出力調整
//
// ヴィジュアルエディタ出力調整用css
//

@use "sass:math";

.l-post-content {
  word-break: break-all;

  // clearfix
  &::after {
    content: "";
    display: block;
    clear: both;
  }

  > p,
  div > p {
    margin-bottom: 1.6em;
  }
  h1 {
    // 基本的に利用されることはないはず
    font-size: rem-calc(32);
    line-height: math.div(52, 32)*1;
    letter-spacing: 0.05em;
  }

  h2 {
    font-size: rem-calc(25);
    line-height: math.div(42, 25)*1;
    letter-spacing: 0.05em;
    margin-top: rem-calc(80);
    margin-bottom: rem-calc(32);
    border-bottom: 2px solid $color-primary;
    padding-bottom: rem-calc(14);
    @include breakpoint(small only) {
      margin-top: rem-calc(40);
      font-size: rem-calc(18);
    }

  }

  h3 {
    font-size: rem-calc(20);
    line-height: 35*0.05*1;
    letter-spacing: 0.05em;
    margin-top: rem-calc(54);
    margin-bottom: rem-calc(24);

    padding-top: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    background-color: #e7e7e7;
    @include breakpoint(small only) {
      margin-top: rem-calc(32);
      font-size: rem-calc(16);
    }

  }


  h4 {
    font-size: rem-calc(17);
    line-height: math.div(32, 17)*1;
    letter-spacing: 0.05em;
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(16);

    &::before {
      float: left;
      margin-right: 0.3rem;
      font-family: 'FontAwesome';
      content: "\f10c";
      color: $color-primary;
    }

    @include breakpoint(small only) {
      font-size: rem-calc(15);
    }

  }

  h5 {
    font-size: rem-calc(16);
    letter-spacing: 0.05em;
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(16);
    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }

  h6 {
    font-size: rem-calc(16);
    letter-spacing: 0.05em;
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(16);
    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }

  dl {
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(16);

    dt {
      font-weight: bold;
    }

    dd {
      margin-bottom: 1em;
    }
  }

  table {
    @extend .c-table;
    letter-spacing: 0;
    margin-top: 1em;
    margin-bottom: 2em;
    width: 100%;

    tbody {
      th,
      td {
        text-align: left;
        padding: rem-calc(16) rem-calc(6);
        vertical-align: top;
        @include breakpoint(small only) {
          padding: rem-calc(8) rem-calc(4);
        }
      }

      th {
        width: rem-calc(120);
        position: relative;
        @include breakpoint(small only) {
          width: rem-calc(96);
        }
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  ol,ul{
    li{
      list-style: inherit;
    }
  }
  ul {
    padding-left: rem-calc(24px);
    list-style: disc;
    //@extend .c-list.is-icon;
  }
  ol {
    padding-left: rem-calc(24px);
    list-style: decimal;
    //@extend .c-list.is-outline;
  }

  iframe {
    max-width: 100%;
    width: 100%;
  }

  pre {
    background: #f5f5f5;
    padding: rem-calc(16) rem-calc(24);
  }

  blockquote {
    @extend .c-blockquote;
  }
}
