.c-block-access {

  &__inner {
    max-width: rem-calc(944);
    margin: 0 auto;
  }

  &__iframe {
    position: relative;
    padding-top: calc(550/900*100%);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      border: none !important;
    }
  }
}
