// テキスト
//
// Styleguide 1.2.0

// テキスト - 通常
//
// Styleguide 1.2.1

p,
.u-text-normal {
  margin: 0;
}

// テキスト - 縮小
//
// Styleguide 1.2.2

small,
.u-text-small {
  font-size: 0.85em;
}

// テキスト - 強調
//
// Styleguide 1.2.3

strong,
.u-text-strong {
  font-weight: 700;
}

// テキスト - 打ち消し線
//
// Styleguide 1.2.4

del,
.u-text-del {
  text-decoration: line-through;
}

// テキスト - リンク
// Styleguide 1.2.5

a,
.u-text-link {
  color: $color-secondary;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}
a{
  &.is-pdf {

    &::after {
      content: "\f1c1";
      font-family: "FontAwesome";
      margin-left: rem-calc(4);
      color: $color-accent;
    }
  }

  &.is-pdf-mt {

    &:after {
      display: inline-block;
      content: "picture_as_pdf";
      font-family: 'Material Icons Round';
      line-height: 1;
      margin-left: rem-calc(4);
      vertical-align: middle;
    }
  }

  &.is-map {
    //下線が気になるようであれば下記を使用
    //text-underline-offset: 3px;

    &::before {
      content: "\f041";
      font-family: "FontAwesome";
    }
  }

  &.is-outlink {

    &::after {
      content: "\f08e";
      font-family: "FontAwesome";
    }
  }
}

a:hover,
.u-text-link:hover,
.u-text-link.is-hover {
  opacity: 0.5;
}

a:active,
.u-text-link:active,
.u-text-link.is-active {
  // color: darken($color-primary, 10);
}

// p a:visited {
//   color: #887EBF;
// }


// 用途別テキストカラー
.u-text-danger {
  color: $color-state-danger;
}

.u-text-info {
  color: $color-state-info;
}

.u-text-success {
  color: $color-state-info;
}
