.c-forms{
  padding: rem-calc(80) rem-calc(32);
  background: $color-white;

  @include breakpoint(medium down) {
    padding: rem-calc(56) 0;
  }

  @include breakpoint(small only) {
    padding: rem-calc(40) rem-calc(16);
  }

  &__text{
    text-align: center;
    margin-bottom: rem-calc(48);

    @include breakpoint(small only) {
      margin-bottom: rem-calc(24);
    }
  }

  &__blocks {
    margin-bottom: rem-calc(32);

    @include breakpoint(small only) {
      margin-bottom: rem-calc(24);
    }
  }

  &__block {
    display: flex;
    &:not(:last-child) {
      margin-bottom: rem-calc(32);
      @include breakpoint(small only) {
        margin-bottom: rem-calc(24);
      }
    }
    @include breakpoint(small only) {
      display: block;
    }

    &.is-vertical {
      display: block;

      .c-forms__title {
        min-width: auto;
        justify-content: flex-start;
        margin-bottom: rem-calc(10);
      }
    }
  }
  &__title {
    min-width: rem-calc(210);
    //padding: rem-calc(16) rem-calc(32);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: none;
    margin-right: rem-calc(60);
    @include font-format(18,0.1,24,700);

    @include breakpoint(small only) {
      width: 100%;
      font-size: rem-calc(16);
    }
    &.is-vertical-top {
      align-items: flex-start;
      &.is-just{
        padding-top: rem-calc(40);
        @include breakpoint(small only) {
          padding-top: rem-calc(12);
        }
      }
      .c-forms__label {
        margin-top: rem-calc(3);
      }
    }
  }
  //ラベル
  &__label {
    display: block;
    color: $color-state-danger;
    margin-left: rem-calc(16);

    &.is-normal {
      color: $font-base-color;
    }
  }
  &__content {
    width: 100%;
    //padding: rem-calc(16) rem-calc(32);
    @include breakpoint(small only) {
      //padding: rem-calc(12) 0;
    }
    &.is-column{
      display: flex;
      justify-content: space-between;
      .c-forms__input {
        margin-right: 16px;

        &:last-of-type {
          margin-right: 0;
        }
      }
      input{
        width: 100%;
      }
    }
    &.is-vertical-middle {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  //インプット
  &__input {
    width: 100%;
    input {
      border-radius: 5px;
      padding: rem-calc(12) rem-calc(16) rem-calc(12) rem-calc(24);

      @include breakpoint(small only) {
        padding: rem-calc(12) rem-calc(16);
      }

      &:focus{
        border-color: $color-primary;
      }
    }

    &.is-sm {
      width: rem-calc(180);
      input {
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(12);
      }
    }
  }
  &__select {
    width: 100%;
    select {
      outline: none;
      border-radius: 4px !important;
      background: #F9F9F9;

      &:focus{
        border-color: $color-primary;
      }
    }
    &.is-sm {
      width: rem-calc(180);
    }
  }

  &__textarea {
    textarea {
      border-radius: 5px;
      padding: rem-calc(12) rem-calc(16) rem-calc(12) rem-calc(24);

      @include breakpoint(small only) {
        padding: rem-calc(12) rem-calc(16);
      }

      &:focus{
        border-color: $color-primary;
      }
    }
  }
  //ラジオボタン,チェックボックス
  &__radio,
  &__checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    label {
      display: flex;
      align-items: center;
      margin-right: rem-calc(64);
      @include breakpoint(small only) {
        margin-right: rem-calc(48);
        margin-bottom: rem-calc(8);
      }
    }
    input {
      margin-right: rem-calc(8);
      margin-top: rem-calc(2);
    }

    span {
      &:last-of-type {
        label {
          margin-right: 0;
        }
      }
    }
  }

  &__radio {

    .mw_wp_form .horizontal-item + .horizontal-item {
      margin-left: 0 !important;
    }

    &.is-vertical {
      display: block;

      span {
        display: block;
        &:not(:last-child) {
          margin-bottom: rem-calc(6);
        }
      }
    }
  }
  &__checkbox {

    &.is-vertical {
      display: block;

      span {
        display: block;
        &:not(:last-child) {
          margin-bottom: rem-calc(6);
        }
      }
    }
    input {
      margin-right: rem-calc(6);
      margin-top: rem-calc(4);
    }
  }
  &__privacy {
    text-align: center;
    margin-top: rem-calc(40);

    @include breakpoint(small only) {
      margin-top: rem-calc(24);
    }
    input{
      margin-right: rem-calc(10);
    }
    a {
      font-weight: 400;
      text-decoration: underline;
      color: $font-base-color;
    }
  }
  &__flex-al{
    display: flex;
    align-items: center;
  }
  &__flexbox {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(16);
    @include breakpoint(small only) {
      display: block;
    }
    input{
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      min-width: rem-calc(120);
      display: block;
      @include breakpoint(small only) {
        min-width: 100%;
        margin-bottom: rem-calc(8);
      }
    }
  }

  &__scroll {
    color: #464646;
    @include font-format(15,0.25,24,400);
    background: #F5F5F5;
    padding: rem-calc(48);
    border-radius: 5px;
    margin-top: rem-calc(40);
    overflow-y: scroll;
    max-height: rem-calc(240);

    @include breakpoint(small only) {
      font-size: rem-calc(14);
      letter-spacing: 0.15em;
      padding: rem-calc(20) rem-calc(16);
      margin-top: rem-calc(24);
      max-height: rem-calc(200);
    }
  }

  // 住所自動入力ボタン
  &__button {
    flex: none;
    max-width: rem-calc(150);
    border: 1px solid $font-base-color;
    outline: none;
    background-color: $color-white;
    padding: rem-calc(3) rem-calc(12);
    display: block;
    text-align: center;
    border-radius: 4px;
    margin-left: rem-calc(16);

    @include breakpoint(small only) {
      padding: rem-calc(9) rem-calc(6);
    }
  }
  // 送信ボタン
  &__submit {
    text-align: center;
    margin-top: rem-calc(40);
    @include breakpoint(small only) {
      margin-top: rem-calc(32);
    }
    &__back{
      width: calc(25% - 28px);
      // -2px はタグの改行によって発生するホワイトスペースの距離
      margin-right:(map_get($grid-column-responsive-gutter,medium) * 0.5) - 2px;
      @include breakpoint(medium down) {
        width: calc(33.33333% - 28px);
      }
      @include breakpoint(small only) {
        width: calc(50% - 10px);
        margin-right: map_get($grid-column-responsive-gutter,small) * 0.5 - 2px;
      }
    }
    &__submit{
      width: calc(25% - 28px);
      margin-left: map_get($grid-column-responsive-gutter,medium) * 0.5 - 2px;
      @include breakpoint(medium down) {
        width: calc(33.33333% - 28px);
      }
      @include breakpoint(small only) {
        width: calc(50% - 10px);
        margin-left: map_get($grid-column-responsive-gutter,small) * 0.5 - 2px;
      }
    }
  }
}
