/*
---
name: ヒーローブロック_通常
category: SpecialComponents
---
*/

.c-hero-block {
  overflow: hidden;

  &__wrapper {
    max-width: rem-calc(1400);
    margin: 0 auto;
  }

  &__inner {
    display: flex;

    @include breakpoint(small only) {
      flex-direction: column-reverse !important;
    }

    &:not(:first-child) {
      margin-top: rem-calc(64);

      @include breakpoint(small only) {
        margin-top: rem-calc(24);
      }
    }

    &:nth-child(odd) {
      margin-right: calc(80/1440*100%);

      @include breakpoint(1200 down) {
        margin-right: rem-calc(32);
      }

      @include breakpoint(small only) {
        margin-right: 0;
      }

      .c-hero-block__content {
        border-radius: 0 20px 20px 0;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
      margin-left: calc(80/1440*100%);

      @include breakpoint(1200 down) {
        margin-left: rem-calc(32);
      }

      @include breakpoint(small only) {
        margin-left: 0;
      }

      .c-hero-block__content {
        border-radius: 20px 0 0 20px;
      }
    }
  }

  &__image {
    position: relative;
    width: calc(720/1360*100%);
    padding-top: calc(430/1360*100%);

    @include breakpoint(small only) {
      width: 100%;
      padding-top: calc(430/720*100%);
    }

    .bg-img {
      @include bg-img();
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(640/1360*100%);
    background: $color-white;
    padding: rem-calc(56) rem-calc(50);

    @include breakpoint(medium down) {
      padding: rem-calc(40) rem-calc(32);
    }

    @include breakpoint(small only) {
      width: 100%;
      padding: rem-calc(24) rem-calc(16);
      border-radius: 20px 20px 0 0 !important;
    }
  }

  &__number {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-bottom: rem-calc(28);

    @include breakpoint(small only) {
      justify-content: center;
      margin-bottom: rem-calc(24);
    }

    &:before, &:after {
      content: "";
      width: rem-calc(10);
      height: rem-calc(10);
      border-radius: 50%;
      background: $color-primary;

      @include breakpoint(small only) {
        width: rem-calc(8);
        height: rem-calc(8);
      }
    }

    &:before {
      margin-right: rem-calc(15);

      @include breakpoint(small only) {
        margin-right: rem-calc(8);
      }
    }

    &:after {
      margin-left: rem-calc(15);

      @include breakpoint(small only) {
        margin-left: rem-calc(8);
      }
    }

    small {
      color: $color-primary;
      @include font-format(20,0.05,20,300);
      margin-right: rem-calc(8);

      @include breakpoint(small only) {
        font-size: rem-calc(16);
      }
    }

    span {
      color: $color-primary;
      @include font-format(40,0,40,700);

      @include breakpoint(small only) {
        font-size: rem-calc(32);
      }
    }
  }

  &__title {

    span {
      display: table;
      @include font-format(40,0.05,64,700);
      background: $color-accent;
      padding: rem-calc(2) rem-calc(12);

      @include breakpoint(medium down) {
        font-size: rem-calc(32);
      }

      @include breakpoint(small only) {
        font-size: rem-calc(28);
      }

      &:not(:first-child) {
        margin-top: rem-calc(8);

        @include breakpoint(small only) {
          margin-top: rem-calc(4);
        }
      }
    }
  }

  &__text {
    @include font-format(20,0.05,32,400);
    margin-top: rem-calc(10);

    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }

  &__button {
    margin-top: rem-calc(64);
    text-align: center;

    @include breakpoint(small only) {
      margin-top: rem-calc(32);
    }
  }
}
