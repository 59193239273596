// プロフィールボックス
// Styleguide 3.1.0

.p-profile-box {

  border: 1px solid $color-tertiary;
  padding: $margin-md;

  @extend %clearfix;

  &__thumbnail {
    float: left;
    text-align: center;
    margin-right: $margin-md;
  }

  &__content {
    //@include span(9);
  }

  &__authorname {
    margin-top: 0;
    font-size: rem-calc(18);
    line-height: rem-calc(18);
  }

}
