.l-block {
  &__margin-normal {
    margin-top: rem-calc(56);
    @include breakpoint(small only) {
      margin-top: rem-calc(56)*0.5;
    }
  }

  &__margin-large {
    margin-top: rem-calc(64);
    @include breakpoint(small only) {
      margin-top: rem-calc(64)*0.5;
    }
  }

  &__margin-medium {
    margin-top: rem-calc(48);
    @include breakpoint(small only) {
      margin-top: rem-calc(24)*0.5;
    }
  }

  &__margin-small {
    margin-top: rem-calc(24);
    @include breakpoint(small only) {
      margin-top: rem-calc(24)*0.5;
    }
  }

  &__margin-none {
    margin-top: 0;
  }
}
