/*
---
name: フッター_サイトマップ型
category: Layout
---
*/

.l-footer {
  position: relative;
  padding: rem-calc(46) 0 rem-calc(20);

  @include breakpoint(small only) {
    padding: rem-calc(40) 0 rem-calc(10);
  }

  &__inner {
    display: flex;
    align-items: flex-end;

    @include breakpoint(small only) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__content {
    flex: none;

    @include breakpoint(small only) {
      margin-top: rem-calc(40);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    text-decoration: none;

    @include breakpoint(small only) {
      display: block;
    }

    b {
      display: block;
      @include font-format(30,0.16,30,900);

      @include breakpoint(small only) {
        font-size: rem-calc(16);
      }
    }

    span {
      display: block;
      color: #464646;
      @include font-format(12,0.09,14.4,500);
      margin-left: rem-calc(20);

      @include breakpoint(small only) {
        font-size: rem-calc(14);
        margin-left: 0;
        margin-top: rem-calc(10);
      }
    }
  }
  &__address {
    display: block;
    color: #464646;
    font-style: normal;
    @include font-format(15,0.09,24,500);
    margin-top: rem-calc(30);

    @include breakpoint(small only) {
      font-size: rem-calc(14);
      margin-top: rem-calc(10);
    }
  }
  &__copyright {
    display: block;
    color: #ABABAB;
    text-align: center;
    @include font-format(12,0.09,19.2,500);
    margin-top: rem-calc(80);

    @include breakpoint(small only) {
      font-size: rem-calc(10);
      margin-top: rem-calc(40);
      text-align: left;
    }
  }

  &__button {
    width: 100%;
    max-width: rem-calc(220);
    transform: translateX(-50%);

    @include breakpoint(small only) {
      max-width: none;
      transform: none;
    }

    .c-button {

      @include breakpoint(small only) {
        max-width: 100%;
      }
    }
  }
}
