/*
---
name: <table> テーブル_小
category: Base
---
*/

@use "sass:math";

.c-table-xlg {
  width: 100%;
  border-top: 1px solid $border-base-color;
  @include breakpoint(small only) {
    width: rem-calc(944);
  }

  th,
  td {
    border-bottom: 1px solid $border-base-color;
  }

  thead {
    background-color: $color-secondary;

    th,
    td {
      padding: rem-calc(10) rem-calc(16);
      @include breakpoint(small only) {
        padding: rem-calc(6) rem-calc(8);
      }
    }
  }

  tbody {
    th,
    td {
      padding: rem-calc(20) rem-calc(32);
      font-weight: 400;
      @include breakpoint(small only) {
        padding: rem-calc(12) rem-calc(24);
      }
    }

    th {
      background-color: rgba($color-secondary, .9);
      text-align: center;
      vertical-align: middle;
      width: math.div(294, 1140)*100%;
    }
  }
}

//スマホ時
.js-responsive-table {

  position: relative;

  &::before{
    @include transition(.2);
    @include breakpoint(small only) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 20px;
      background-repeat: no-repeat;
      background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2));
      background-attachment: scroll;
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  &::after{
    @include transition(.2);
    @include breakpoint(small only) {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 20px;
      background-repeat: no-repeat;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2));
      background-attachment: scroll;
      pointer-events: none;
    }
  }

  &.is-scrollable {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }

  &.is-unscrollable {
    &::after {
      visibility: hidden;
      opacity: 0;
    }
  }

  &-wrapper {
    @include breakpoint(small only) {
      width: 100%;
      overflow: auto;
    }
  }

  @include breakpoint(small only) {
  }
}
