.l-global-nav {
  border-top: 1px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
  @include breakpoint(medium down) {
    display: none;
  }
  ul {
    display: flex;
    li {
      width: 100%;
      text-align: center;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: calc(100% - 16px);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-color: #E2E2E2;
      }
      &:first-child {
        &::before {
          content: "";
          display: block;
          width: 1px;
          height: calc(100% - 16px);
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: #E2E2E2;
        }
      }
      a {
        display: block;
        color: $font-base-color;
        font-weight: 400;
        text-decoration: none;
        font-size: 15px;
        padding: 12px 12px;
        position: relative;
        //装飾
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background-color: $color-primary;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: all .3s ease;
        }
        //*hover
        &:hover {
          opacity: 1;
          &::after {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
}
