/*
---
name: <table> テーブル_大
category: Base
---
*/
@use "sass:math";

.c-table-sm {
  width: 100%;
  border-top: 1px solid $border-base-color;
  letter-spacing: 0.02em;

  th,
  td {
    border-bottom: 1px solid $border-base-color;
    border-right: 1px solid $border-base-color;

    &:last-child {
      border-right: none;
    }
  }

  thead {
    background-color: rgba($color-primary, 0.7);
    color: $color-white;

    th,
    td {
    }

    th {
      text-align: left;
      padding: rem-calc(10) rem-calc(16);
      @include breakpoint(small only) {
        padding: rem-calc(5) rem-calc(8);
      }
    }

    td {
      text-align: center;
      padding: rem-calc(10) rem-calc(8);
      @include breakpoint(small only) {
        padding: rem-calc(5) rem-calc(4);
      }
    }
  }

  tbody {
    th,
    td {
      padding: rem-calc(18) rem-calc(8);
      font-weight: 400;
      text-align: center;
      @include breakpoint(small only) {
        padding: rem-calc(8) rem-calc(4);
      }
    }

    th {
      background-color: rgba($color-primary, 0.1);
      vertical-align: middle;
      width: math.div(162, 650)*100%;

      span {
        font-weight: 700;
      }
    }

    td {
      width: math.div(70, 650)*100%;

      span {
        color: $color-primary;
      }
    }
  }
}
