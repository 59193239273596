.c-gallery-slider {
  position: relative;
  z-index: 1;
  overflow: hidden;

  &__inner {

    &:not(:first-child) {
      margin-top: rem-calc(20);
    }
  }

  &__image {
    width: rem-calc(360) !important;
    height: rem-calc(260) !important;
    border-radius: 20px;
    margin: 0 rem-calc(12);
    @include bg-option();

    @include breakpoint(small only) {
      width: rem-calc(194) !important;
      height: rem-calc(140) !important;
      border-radius: 16px;
      margin: 0 rem-calc(6);
    }
  }
}
