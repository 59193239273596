// テキスト : 位置
//
// .u-text-center : センター寄せ
//
// .u-text-left : 左寄せ
//
// .u-text-right : 右寄せ
// Styleguide 4.1.0

.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}
