/*
---
name: カード_通常
category: Components
---
*/
.c-card {
  &__block {
    width: 100%;
    display: block;
    text-decoration: none;
    color: $font-base-color;
    font-weight: 400;
    @include breakpoint(small only) {
      margin-bottom: rem-calc(32) !important;
    }

    span,
    small {
      display: block;
    }
  }

  &__image {
    transition: all .2s;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    margin-top: rem-calc(12);
  }

  &__title {
    font-weight: 700;
    font-size: rem-calc(20);
    letter-spacing: 0.1em;
    margin: 0;
    @include breakpoint(small only) {
      font-size: rem-calc(20)*0.9;
    }
  }

  &__text {
    margin-top: rem-calc(12);
    font-size: rem-calc(14);
    @include breakpoint(small only) {
      margin-top: rem-calc(6);
    }
  }
}

/*
---
name: カード_数字付き
category: Components
---
*/
.c-card {
  &.is-number-left {
    .c-card__block {
      position: relative;
      padding-top: rem-calc(52);
    }

    .c-card__number {
      text-align: center;
      color: $color-primary;
      @include webfont();
      line-height: 1;
      position: absolute;
      top: 0;
      left: 8px;

      small {
        @include webfont();
        font-size: rem-calc(18);
        letter-spacing: 0.1em;
      }

      span {
        font-size: rem-calc(60);
        letter-spacing: 0;
      }
    }

    .c-card__text {
      font-size: $font-base-size;
      @include breakpoint(small only) {
        font-size: $font-base-size*0.85;
      }
    }
  }
}

.c-card{
  &__list{
    display: block !important;
  }
}
