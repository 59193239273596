/*
---
name: ヒーローブロック_枡目型
category: SpecialComponents
---
*/
@use "sass:math";

.c-hero-block-square {
  &__block {
    position: relative;
    @include breakpoint(small only) {
      margin-bottom: rem-calc(16);
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    min-height: rem-calc(400);
    overflow: hidden;
    @include breakpoint(small only) {
      position: relative;
      width: 100%;
      min-height: 60vw;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      //min-height: 100%;
      max-width: inherit;
      @include breakpoint(small only) {
        width: auto;
        height: 100%;
      }
    }
  }

  &__content {
    width: 50%;
    margin-left: auto;
    padding: rem-calc(56) 0 rem-calc(56) rem-calc(64);
    display: flex;
    min-height: rem-calc(400);
    align-items: center;
    @include breakpoint(small only) {
      width: 100%;
      padding: rem-calc(24) 0 !important;
      min-height: inherit;
      display: block;
    }

    .c-heading {
      @include breakpoint(small only) {
        margin-bottom: rem-calc(16) !important;
      }
    }
  }

  &__button {
    margin-top: rem-calc(32);
    @include breakpoint(small only) {
      margin-top: rem-calc(24);
    }
  }
}

// 偶数番目
.c-hero-block-square__block:nth-child(even),
.c-hero-block-square__block.is-reverse {
  .c-hero-block-square__image {
    left: auto;
    right: 0;
  }

  .c-hero-block-square__content {
    margin-left: 0;
    padding: rem-calc(56) rem-calc(64) rem-calc(56) 0;
  }
}

// 数字付き
.c-hero-block-square.is-number {
  .c-hero-block-square__heading {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(8);

    span {
      @include webfont();
      font-size: 60px;
      line-height: math.div(71, 60)*1;
      letter-spacing: 0;
      color: $color-primary;
      margin-right: rem-calc(16);
      @include breakpoint(small only) {
        font-size: rem-calc(48);
      }
    }

    .c-heading {
      margin-bottom: 0;
    }
  }

}

//比率変更
.c-hero-block-square.is-zigzag {

  .c-hero-block-square__block {
    margin-bottom: rem-calc(80);
  }

  .c-hero-block-square__image {
    width: math.div(718, 1400)*100%;
    @include breakpoint(small only) {
      width: 100%;
    }
  }

  .c-hero-block-square__content {
    width: math.div(550, 1140)*100%;
    padding-top: 0;
    padding-bottom: 0;
    display: block;
    @include breakpoint(small only) {
      width: 100%;
    }
  }

  .c-hero-block-square__title {
    margin-top: 0;
    margin-bottom: rem-calc(24);
  }

  .c-hero-block-square__question {
    margin-bottom: rem-calc(24);
  }

}

