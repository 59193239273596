.c-box-border {

  &__inner {
    position: relative;
    display: flex;
    align-items: center;

    @include breakpoint(medium down) {
      align-items: flex-end;
    }

    @include breakpoint(small only) {
      padding-top: rem-calc(210);
      margin-bottom: rem-calc(40);
    }

    &:before, &:after {
      content: "";
      flex: none;
      width: rem-calc(200);
      height: rem-calc(280);
      @include bg-option();

      @include breakpoint(medium down) {
        width: rem-calc(150);
        height: rem-calc(210);
      }

      @include breakpoint(small only) {
        position: absolute;
        top: 0;
      }
    }

    &:before {
      background-image: url(../images/img-box-border-01.png);
      margin-right: rem-calc(15);

      @include breakpoint(medium down) {
        margin-right: rem-calc(6);
      }

      @include breakpoint(small only) {
        left: 0;
      }
    }

    &:after {
      background-image: url(../images/img-box-border-02.png);
      margin-left: rem-calc(15);

      @include breakpoint(medium down) {
        margin-left: rem-calc(6);
      }

      @include breakpoint(small only) {
        right: 0;
      }
    }
  }

  &__content {
    padding: rem-calc(35);
    border: 4px solid $color-primary;
    border-radius: 20px;

    @include breakpoint(medium down) {
      padding: rem-calc(24);
    }

    @include breakpoint(small only) {
      padding: rem-calc(16);
      border-width: 2px;
    }
  }

  &__title {
    text-align: center;
    color: $color-primary;
    @include font-format(24,0.05,33.6,700);
    margin-bottom: rem-calc(14);

    @include breakpoint(small only) {
      font-size: rem-calc(17);
      margin-bottom: rem-calc(10);
    }
  }

  &__text {
    font-weight: 700;
    line-height: calc(22.4/16*1);
  }
}
