.c-sitemap {
  &__inner {

  }

  &__list {

  }

  &__item {

    &:not(:last-child) {
      margin-bottom: rem-calc(20);
    }

    a {
      text-decoration: none;
      @include font-format(20, 0, 32, bold);
      color: $font-base-color;
      position: relative;
      padding-bottom: rem-calc(10);
      border-bottom: 2px solid $border-base-color;
      display: block;

      @include breakpoint(small only) {
        font-size: rem-calc(16);
      }


      &.is-external {
        &:after {
          display: inline-block;
          content: "\f2d2";
          font-family: FontAwesome;
          color: $color-primary;
          margin-left: rem-calc(14);
        }
      }
    }
    &.is-parent {
      margin-bottom: rem-calc(40);

      @include breakpoint(small only) {
        margin-bottom: rem-calc(20);
      }
    }
  }

  &__flex {
    display: flex;
    align-items: flex-start;
  }

  &__sublist {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem-calc(36);

    @include breakpoint(small only) {
      margin-top: rem-calc(18);
    }

    &.is-three-col {
      width: 75%;

      @include breakpoint(medium down) {
        width: 50%;
      }

      @include breakpoint(small only) {
        width: 100%;
      }

      .c-sitemap__subitem {
        width: 33.3%;

        @include breakpoint(medium down) {
          width: 100%;
        }

        @include breakpoint(small only) {
          width: 50%;
        }
      }
    }

    &.is-one-col {
      width: 25%;

      .c-block-sitemap__subitem {
        width: 100%;
      }
    }
  }


  &__subitem {

    width: 25%;
    margin-bottom: rem-calc(20);
    padding-right: rem-calc(10);

    @include breakpoint(medium down) {
      width: 50%;
    }

    @include breakpoint(small only) {
      margin-bottom: rem-calc(10);
    }

    a {
      @include font-format(16, 0, 25, normal);
      border-bottom: none;
      padding: 0;

      @include breakpoint(small only) {
        font-size: rem-calc(14);
      }
    }


    ul {
      margin-top: rem-calc(20);


    }

    li {


      a {
        @include font-format(13, 0, 28, normal);
        &:before {
          content: "-";
          display: inline-block;
        }

        @include breakpoint(small only) {
          font-size: rem-calc(12);
        }
      }
    }
  }

}
