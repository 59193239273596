// グリッド
//
// 基本的に12グリッドレイアウト方式とする。
//
// * large-{int} : デスクトップ時
// * medium-{int} : タブレット時
// * small-{int} : スマートフォン時
//
// Styleguide 2.2.0

// $susy: (
//   flow: ltr,
//   math: fluid,
//   output: fluid,
//   gutter-position: inside-static,
//   container: $grid-row-width,
//   container-position: center,
//   columns: susy-repeat($grid-column-count),
//   gutters: math.div(map.get($grid-column-responsive-gutter,medium), 60px),
//   column-width: 60px,
//   global-box-sizing: b order-box,
//   last-flow: to,
//   spread: 'wide',
//   container-spread: 'wide',
//   debug: (
//     image: hide,
//     color: rgba(#66f, .25),
//     output: background,
//     toggle: top right,
//   ),
//   use-custom: (
//     background-image: true,
//     background-options: false,
//     box-sizing: true,
//     clearfix: false,
//     rem: true,
//   )
// );

// グリッド : コンテナー
//
// サイトの幅に合わせたセンター寄せのコンテナー
//
//
// Styleguide 2.2.1
@use "sass:math";
@use "sass:map";
.container {
  // @include container($susy);
  max-width: $grid-row-width;
  margin-left: auto;
  margin-right: auto;
}

.row {
  margin-left: -(map.get($grid-column-responsive-gutter, medium) * 0.5);
  margin-right: -(map.get($grid-column-responsive-gutter, medium) * 0.5);
  // @include susy-clearfix();
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  @include breakpoint(small only) {
    margin-left: -(map.get($grid-column-responsive-gutter, small) * 0.5);
    margin-right: -(map.get($grid-column-responsive-gutter, small) * 0.5);
  }

  &.is-center {
    justify-content: center;
  }
}

// グリッド計算用関数

@function gridWidth($value) {
  $base: 100;
  $grid: math.div($grid-column-count, $value);
  @return math.percentage(math.div($base, $grid) * 0.01);
}

// グリッド : スマートフォン
//
// スマートフォン時のグリッドアイテム
//
// markup:
// <div class="small-2">
// </div>
//
// Styleguide 2.2.2

@for $i from 1 through $grid-column-count {
  .small-#{$i} {
    width: gridWidth($i);
    padding-left: (map.get($grid-column-responsive-gutter, small) * 0.5);
    padding-right: (map.get($grid-column-responsive-gutter, small) * 0.5);
  }
  .is-push-sm-#{$i} {
    margin-left: gridWidth($i);
    padding-left: (map.get($grid-column-responsive-gutter, small) * 0.5);
    padding-right: (map.get($grid-column-responsive-gutter, small) * 0.5);
  }
}

// グリッド : タブレット
//
// タブレット時のグリッドアイテム
//
// Styleguide 2.2.3

@include breakpoint(medium only) {
  @for $i from 1 through $grid-column-count {
    .medium-#{$i} {
      width: gridWidth($i);
      padding-left: (map.get($grid-column-responsive-gutter, medium) * 0.5);
      padding-right: (map.get($grid-column-responsive-gutter, medium) * 0.5);
    }
    .is-push-md-#{$i} {
      margin-left: gridWidth($i);
    }
  }
}

// グリッド : デスクトップ
//
// デスクトップ時のグリッドアイテム
// Styleguide 2.2.4

@include breakpoint(medium up) {
  @for $i from 1 through $grid-column-count {
    .large-#{$i} {
      width: gridWidth($i);
      padding-left: (map.get($grid-column-responsive-gutter, medium) * 0.5);
      padding-right: (map.get($grid-column-responsive-gutter, medium) * 0.5);
    }
    .is-push-lg-#{$i} {
      margin-left: gridWidth($i);
    }
  }
}
