/*
---
name: メインビジュアル_通常
category: Components
---
*/

.c-main-visual {
  width: 100%;
  position: relative;
  padding-bottom: rem-calc(100);

  @include breakpoint(small only) {
    padding-bottom: rem-calc(80);
  }

  &:before, &:after {
    content: "";
    position: absolute;
    width: rem-calc(232);
    height: rem-calc(178);
    @include bg-option();
    background-image: url(../images/bg-main-visual-after.png);

    @include breakpoint(small only) {
      width: rem-calc(104);
      height: rem-calc(80);
    }
  }

  &:before {
    top: 0;
    right: 0;
  }

  &:after {
    left: 0;
    bottom: 0;
  }

  &__slider {
    position: relative;
    z-index: 1;
    margin-left: auto;
    width: calc(100% - 96px) !important;

    @include breakpoint(medium down) {
      width: calc(100% - 48px) !important;
    }

    @include breakpoint(small only) {
      width: 100% !important;
    }

    &:after {
      content: "";
      @include bg-img();
      // background-image: url(../images/bg-main-visual-mask.png);
    }
  }

  &__image {
    width: 100%;
    height: 44.24vw;

    @include breakpoint(medium down) {
      min-height: 280px;
    }

    @include breakpoint(small only) {
      height: 100vw;
      min-height: 476px;
    }

    .bg-img {
      width: 100%;
      height: 100%;
      @include bg-option();

      @include breakpoint(small only) {
        background-position: bottom center;
      }
    }
  }

  &__inner {
    display: block;
    position: absolute;
    top: 50%;
    left: calc(176/1440*100%);
    transform: translateY(-50%);
    z-index: 99;

    @include breakpoint(medium down) {
      top: 40%;
      left: 64px;
    }

    @include breakpoint(small only) {
      top: auto;
      bottom: rem-calc(110);
      transform: none;
      left: 0;
    }
  }

  &__title {
    @include font-format(58,0.05,76,700);

    @include breakpoint(medium down) {
      font-size: rem-calc(46);
    }

    @include breakpoint(small only) {
      font-size: rem-calc(32);
    }

    span {
      display: table;
      padding: rem-calc(5) rem-calc(14);
      background: $color-white;

      @include breakpoint(medium down) {
        padding: rem-calc(4) rem-calc(8);
      }

      &:not(:first-child) {
        margin-top: rem-calc(18);

        @include breakpoint(medium down) {
          margin-top: rem-calc(8);
        }
      }
    }

    b {
      color: $color-primary;
    }
  }

  &__scroll {
    writing-mode: vertical-rl;
    position: absolute;
    left: rem-calc(40);
    bottom: rem-calc(100);
    z-index: 100;
    @include font-format(16,0.05,16,600);
    overflow: hidden;

    @include breakpoint(medium down) {
      left: rem-calc(18);
    }

    @include breakpoint(small only) {
      display: none;
    }

    span {
      display: block;
      position: relative;
      padding-bottom: rem-calc(214);

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: rem-calc(120);
        transform: translateX(-50%) translateY(-100%);
        width: rem-calc(1);
        height: rem-calc(100);
        background: $color-secondary;
        animation: main-visual-scrollbar 2.5s ease forwards infinite;
      }
    }
  }

  .owl-carousel {

  }
}
