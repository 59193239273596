// 表示
//
// .u-display-block: ブロック
//
// .u-display-none: 非表示
//
// .u-display-inlineblock: インラインブロック
//
// .u-display-inline: インライン
// Styleguide 4.2.0

.u-display-block {
  display: block !important;
}

.u-display-none {
  display: none !important;
}

.u-display-inlineblock {
  display: inline-block !important;
}

.u-display-inline {
  display: inline !important;
}

