/*
---
name: 見出し_テンプレート
category: Heading
---
*/

.c-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.5;

  span {
    font-size: 0.65em;
  }
}


// h1
.c-heading.is-xlg {
  position: relative;
  text-align: center;
  padding-bottom: rem-calc(20);
  margin-bottom: rem-calc(40);

  @include breakpoint(small only) {
    padding-bottom: rem-calc(16);
    margin-bottom: rem-calc(32);
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(50%);
    width: rem-calc(40);
    height: rem-calc(4);
    background: $color-primary;
  }

  b {

    &.is-eng {
      display: block;
      @include font-format(20, 0.05, 24, 600);
      margin-bottom: rem-calc(16);

      @include breakpoint(small only) {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(12);
      }
    }
  }

  span {

    &.is-ja {
      display: block;
      color: $color-primary;
      @include font-format(40, 0.05, 58, 600);

      @include breakpoint(small only) {
        font-size: rem-calc(24);
      }
    }
  }
}

// h2
.c-heading.is-lg {
  color: $color-primary;
  @include font-format(32, .1, 40, bold);
  margin-bottom: rem-calc(24);
  @include breakpoint(small only) {
    font-size: rem-calc(32)*0.8;
    margin-bottom: rem-calc(24)*0.5;
  }

  b {

    &.is-eng {

    }
  }

  span {

    &.is-ja {
      display: block;
      @include webfont();
      @include font-format(14, .1, 20, normal);
      margin-top: rem-calc(6);
      @include breakpoint(small only) {
        font-size: rem-calc(14)*0.8;
      }
    }
  }
}

// h3
.c-heading.is-md {
  @include font-format(28, .1, 38, 700);
  margin-bottom: rem-calc(20);
  @include breakpoint(small only) {
    font-size: rem-calc(28)*0.8;
    margin-bottom: rem-calc(20)*0.5;
  }
}

// h4
.c-heading.is-sm {
  @include font-format(24, .1, 34, 700);
  @include breakpoint(small only) {
    font-size: rem-calc(24)*0.8;
  }
}

// h5
.c-heading.is-xs {
  @include font-format(20, .1, 30, 700);
  margin-bottom: rem-calc(16);
  @include breakpoint(small only) {
    font-size: rem-calc(20)*0.8;
    margin-bottom: rem-calc(16)*0.5;
  }
}

// h6
.c-heading.is-xxs {
  @include font-format(18, .1, 28, 700);
  margin-bottom: rem-calc(6);
  @include breakpoint(small only) {
    font-size: rem-calc(18)*0.8;
  }
}

.c-heading.is-color-primary {
  color: $color-primary;
}

.c-heading.is-color-white {

  &:after {
    background: $color-white;
  }

  b {

    &.is-eng {
      color: $color-white;
    }
  }

  span {

    &.is-ja {
      color: $color-white;
    }
  }
}

// マージン調整
.c-heading.is-top {
  margin-bottom: 0 !important;
}

.c-heading.is-bottom {
  margin-top: 0 !important;
}

.c-heading.is-center{
  text-align: center;
}

.c-heading.is-mg-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
