.c-pagetop {
  position: fixed;
  right: rem-calc(32);
  bottom: rem-calc(64);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  @include transition();

  @include breakpoint(small only) {
    right: rem-calc(16);
    bottom: rem-calc(50);
  }

  &.is-fixed {
    opacity: 1;
    visibility: visible;
  }

  a {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: rem-calc(77);
    height: rem-calc(77);
    padding: rem-calc(10);
    border-radius: 50%;
    border: 1px solid $color-primary;
    color: $color-primary;
    background: $color-white;
    text-decoration: none;
    @include transition();
    @include font-format(12,0,12,700);

    &:before {
      content: "▲";
      margin-bottom: rem-calc(14);
    }

    &:hover {
      opacity: 1;
      color: $color-white;
      background: $color-primary;
    }
  }
}
