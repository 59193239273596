// メインカラム
//
// ページのメインとなるコンテンツを囲むラッパ
//
// markup:
// <div class="l-main"></div>
//
// Styleguide 5.6.0

// メインカラム


.l-main {
  width: 100%;
  max-width: rem-calc(1920);
  margin: 0 auto;
}

.l-main.is-two-column {
  padding: rem-calc(80) 0;
  @include breakpoint(medium down) {
    padding: rem-calc(40) 0;
  }
}
.l-main.is-two-column .l-container {
  display: flex;
  align-items: stretch;
  @include breakpoint(medium down) {
    display: block;
  }
  & .l-wrapper {
    max-width: $mainContainer;
    margin-right: rem-calc($asideMargin);
    width: 100%;
    @include breakpoint(medium down) {
      margin:auto;
      max-width: inherit;
    }
  }
}
