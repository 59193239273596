// パンくずナビゲーション
// Styleguide 2.3.0
.c-breadcrumb {
  padding: rem-calc(50) 0;

  @include breakpoint(medium down) {
    padding: rem-calc(32) 0;
  }

  @include breakpoint(small only) {
    padding: rem-calc(24) 0;
  }

  &__inner {

    @include breakpoint(small only) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      @include font-format(12,0.1,19,700);

      &.is-arrow {
        margin:0 rem-calc(10);
        align-items: center;
      }

      a {
        font-weight: 700;
      }
    }
  }
}
