.c-block-recruit {

  &__inner {
    display: flex;
    align-items: flex-start;

    @include breakpoint(small only) {
      display: block;
    }
  }

  &__content {
    width: calc(500/1140*100%);

    @include breakpoint(small only) {
      width: 100%;
    }
  }

  &__title {
    color: $color-primary;
    @include font-format(40,0.05,56,700);
    margin-bottom: rem-calc(15);

    @include breakpoint(small only) {
      font-size: rem-calc(28);
      margin-bottom: rem-calc(10);
    }
  }

  &__image {
    position: relative;
    padding-top: calc(360/500*100%);

    .bg-img {
      @include bg-img();
      border-radius: 40px;

      @include breakpoint(small only) {
        border-radius: 20px;
      }
    }
  }

  &__texts {
    width: calc(600/1140*100%);
    margin-left: rem-calc(40);

    @include breakpoint(small only) {
      width: 100%;
      margin: rem-calc(32) auto 0;
    }
  }

  &__text {
    line-height: 2;
    font-weight: 600;
  }

  &__name {
    @include font-format(20,0.05,32,600);
    margin-top: rem-calc(40);

    @include breakpoint(small only) {
      font-size: rem-calc(16);
      margin-top: rem-calc(24);
    }
  }

  &__table {

  }

  &__button {
    text-align: center;
    margin-top: rem-calc(64);

    @include breakpoint(small only) {
      margin-top: rem-calc(32);
    }
  }
}
