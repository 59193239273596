/*
---
name: アコーディオン_Q&A
category: Components
---
*/

.c-accordion {
  &__block {
    border: 1px solid $border-base-color;
    padding: 0 rem-calc(32);
    margin-bottom: rem-calc(16);
    @include breakpoint(small only) {
      padding: 0 rem-calc(16);
      margin-bottom: rem-calc(8);
    }
    // オープン時
    &.is-open {
      .c-accordion__head {
        &::after {
          content: "\f106";
        }
      }
    }
  }
  &__head {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    padding: rem-calc(16) rem-calc(24) rem-calc(16) 0;
    cursor: pointer;
    position: relative;
    @include breakpoint(small only) {
      padding: rem-calc(16) rem-calc(24) rem-calc(12) 0;
    }
    //*矢印
    &::after {
      content: "\f107";
      font-family: "FontAwesome";
      color: $color-primary;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      @include breakpoint(small only) {
        top: rem-calc(20);
        transform: translateY(0);
      }
    }
  }
  &__title {
    font-weight: 700;
    letter-spacing: 0.05em;
    color: $color-primary;
    padding-top: rem-calc(6);
    @include breakpoint(small only) {
      padding-top: rem-calc(0);
    }
  }
  &__icon {
    color: $color-white;
    @include webfont();
    font-size: rem-calc(22);
    background-color: $color-primary;
    width: rem-calc(40);
    height: rem-calc(40);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: rem-calc(16);
    padding-left: rem-calc(2);
    @include breakpoint(small only) {
      width: rem-calc(32);
      height: rem-calc(32);
      font-size: rem-calc(18);
    }
    &.is-color-accent {
      background-color: $color-accent;
      position: absolute;
      top: rem-calc(16);
      left: 0;
    }
  }
  &__content {
    padding: rem-calc(24) 0 rem-calc(24) rem-calc(56);
    position: relative;
    border-top: 1px solid $border-base-color;
    display: none;
    @include breakpoint(small only) {
      padding: rem-calc(16) 0 rem-calc(16) rem-calc(48);
    }
  }
}
