/*
---
name: ステップボックス_画像なし
category: Components
---
*/
.c-box-number {
  &__block {
    display: flex;
    position: relative;
    margin-bottom: rem-calc(40);
    @include breakpoint(small only) {
      margin-bottom: rem-calc(32);
    }
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 16px 12px 0 12px;
      border-color: $color-primary transparent transparent transparent;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%,100%);
    }
    //*最後は矢印なし
    &:last-child {
      margin-bottom: 0;
      &::after {
        display: none;
      }
    }
  }
  //*ナンバー
  &__head {
    background-color: $color-primary;
    width: rem-calc(86);
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(small only) {
      width: rem-calc(72);
    }
  }
  &__number {
    color: $color-white;
    text-align: center;
    @include webfont();
    line-height: 1.2;
    small {
      @include webfont();
      font-size: rem-calc(16);
      letter-spacing: 0.1em;
      display: block;
      @include breakpoint(small only) {
        font-size: rem-calc(14);
      }
    }
    span {
      font-size: rem-calc(32);
      letter-spacing: 0;
      display: block;
      @include breakpoint(small only) {
        font-size: rem-calc(32);
      }
    }
  }
  //*テキストコンテンツ
  &__content {
    background-color: $color-secondary;
    padding: rem-calc(28) rem-calc(40);
    width: calc(100% - 86px);
    @include breakpoint(small only) {
      padding: rem-calc(16) rem-calc(24);
      width: calc(100% - 72px);
    }
  }
  &__text {
    font-size: rem-calc(14);
  }
}
