.c-hero-block-center {

  &__wrapper {
    background: $color-white;
    padding: rem-calc(80) 0;

    @include breakpoint(small only) {
      padding: rem-calc(40) 0;
    }
  }

  &__inner {
    display: flex;
    align-items: center;

    @include breakpoint(medium down) {
      align-items: flex-start;
    }

    @include breakpoint(small only) {
      flex-direction: column !important;
    }

    &:not(:first-child) {
      margin-top: rem-calc(64);

      @include breakpoint(small only) {
        margin-top: rem-calc(48);
      }
    }

    &:nth-child(odd) {

      .c-hero-block-center__content {
        margin-left: rem-calc(40);
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .c-hero-block-center__content {
        margin-right: rem-calc(40);
      }
    }
  }

  &__image {
    position: relative;
    width: calc(500/1140*100%);
    padding-top: calc(500/1140*100%);

    @include breakpoint(small only) {
      width: 100%;
      padding-top: 100%;
    }

    .bg-img {
      @include bg-img();
      border-radius: 40px;

      @include breakpoint(small only) {
        border-radius: 20px;
      }
    }
  }

  &__content {
    width: calc(600/1140*100%);

    @include breakpoint(small only) {
      width: 100%;
      margin: rem-calc(24) auto 0 !important;
    }
  }

  &__subtitle {
    display: table;
    @include font-format(32,0.16,40,600);
    background: $color-accent;
    padding: rem-calc(12) rem-calc(15);
    margin-bottom: rem-calc(20);

    @include breakpoint(small only) {
      font-size: rem-calc(24);
      padding: rem-calc(6) rem-calc(10);
      margin-bottom: rem-calc(12);
    }
  }

  &__title {
    color: $color-primary;
    @include font-format(40,0.05,52,700);
    margin-bottom: rem-calc(20);

    @include breakpoint(small only) {
      font-size: rem-calc(28);
      margin-bottom: rem-calc(12);
    }
  }

  &__text {
    @include font-format(20,0.05,32,400);

    @include breakpoint(small only) {
      font-size: rem-calc(16);
    }
  }


  &.is-single {

    .c-hero-block-center__image {

      .bg-img {
        border-radius: 0 40px 40px 0;

        @include breakpoint(small only) {
          border-radius: 20px;
        }
      }
    }

    .c-hero-block-center__text {

      @include font-format(25,0.05,40,500);

      @include breakpoint(small only) {
        font-size: rem-calc(18);
      }
    }
  }
}
