.c-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin:auto;
  width: 100vw;
  height: 100vh;
  background: $color-white;
  z-index: 99999;
  transition: 1s;
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @include font-format(40,.1,50,bold);
    @include webfont;
    opacity: 0;
    transition: 1s;
  }

  &.is-active {
    .c-loader__title {
      transition: 1s;
      opacity: 1;
    }
  }
  &.is-close {
    height: 0;
    transition: 1s;
    opacity: 0;
  }
  &.is-hidden {
    visibility: hidden;
  }

  &.is-already {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}

