// セクション
//
// セクションのスタイル
//
// markup:
// <div class="l-section [is-lg | is-md | is-sm | is-bottom | is-top]"></div>
//
// Styleguide 5.3.0

$xlg: 120;
$lg: 100;
$md: 80;
$sm: 64;
$xs: 32;

.l-section {
  // サイズ設定


  &.is-xlg {
    padding-top: rem-calc($xlg);
    padding-bottom: rem-calc($xlg);
    @include breakpoint(small only) {
      padding-top: rem-calc($xlg) * 0.5;
      padding-bottom: rem-calc($xlg) * 0.5;
    }
  }

  &.is-lg {
    padding-top: rem-calc($lg);
    padding-bottom: rem-calc($lg);
    @include breakpoint(small only) {
      padding-top: rem-calc($lg) * 0.5;
      padding-bottom: rem-calc($lg) * 0.5;
    }
  }

  &.is-md {
    padding-top: rem-calc($md);
    padding-bottom: rem-calc($md);
    @include breakpoint(small only) {
      padding-top: rem-calc($md) * 0.5;
      padding-bottom: rem-calc($md) * 0.5;
    }
  }

  &.is-sm {
    padding-top: rem-calc($sm);
    padding-bottom: rem-calc($sm);
    @include breakpoint(small only) {
      padding-top: rem-calc($sm) * 0.5;
      padding-bottom: rem-calc($sm) * 0.5;
    }
  }
  &.is-xs {
    padding-top: rem-calc($xs);
    padding-bottom: rem-calc($xs);
    @include breakpoint(small only) {
      padding-top: rem-calc($xs) * 0.5;
      padding-bottom: rem-calc($xs) * 0.5;
    }
  }

  // カラー設定
  //->プライマリー
  &.is-color-primary {
    background-color: $color-primary;
  }
  //->セカンダリー
  &.is-color-secondary {
    background-color: $color-secondary;
  }

  // マージン設定
  &.is-top {
    padding-bottom: 0 !important;
  }

  &.is-bottom {
    padding-top: 0 !important;
  }

  &.is-bg-color{
    background: $color-background;
  }

  &.is-radius{
    border-radius: 150px 150px 0 0;

    @include breakpoint(small only) {
      border-radius: 80px 80px 0 0;
    }
  }

  &.is-before-after {
    position: relative;

    &:before, &:after {
      content: "";
      position: absolute;
      width: rem-calc(240);
      height: rem-calc(100);
      @include bg-option();

      @include breakpoint(small only) {
        width: rem-calc(104);
        height: rem-calc(80);
      }
    }

    &:before {
      top: 0;
      right: 0;
      background-image: url(../images/bg-section-before.png);

      @include breakpoint(small only) {
        background-image: url(../images/bg-main-visual-before.png);
      }
    }

    &:after {
      bottom: 0;
      left: 0;
      background-image: url(../images/bg-section-after.png);

      @include breakpoint(small only) {
        background-image: url(../images/bg-main-visual-after.png);
      }
    }
  }
}
