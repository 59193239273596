/*
---
name: お知らせ_目次
category: PostContent
---
# HTMLは自動生成
*/

#toc_container {
  margin: rem-calc(32) 0;
  width: 100% !important;
  background: $color-secondary;
  border-radius: 0px;
  padding: rem-calc(16) rem-calc(40) rem-calc(40);
  @include breakpoint(small only) {
    margin: rem-calc(16) 0;
    padding: rem-calc(16) rem-calc(24) rem-calc(24);
  }

  .toc_title {
    text-align: center !important;
    font-size: 1.125rem;
    color: $color-primary;
    font-weight: 700;
    margin-bottom: rem-calc(16);

    .toc_toggle {
      margin-left: rem-calc(16);
    }
  }

  .toc_list{
    list-style: none;
  }
  .toc_list li {
    &::before {
      display: none;
    }

    ul {
      list-style: none;
      padding-left: 24px;
      margin-left: 0;
    }

    a {
      display: block;
      padding-left: rem-calc(16);
      transition: all ease .2s;
      text-decoration: none;
      color: $font-base-color;
      font-weight: 400;

      &:hover {
        text-decoration: none;
        background: rgba(214, 214, 214, 0.2);
      }
    }

    &:last-child {
      box-shadow: none;
    }
  }
}
