// スライドメニュー
//
// スマホ時のメニュー。別途 GApp プラグインを呼び出す必要があります。
// Styleguide 4.5

$slidebar-menu-bg: $color-primary !default;
$slidebar-menu-width: 75% !default;
$slidebar-menu-eaasing: all ease-in-out .2s !default;
$slidebar-container-bg: rgba(0, 0, 0, 0.8) !default;

@include breakpoint(large up) {
  .c-slidebar-button {
    display: none;
  }
  .c-slidebar-menu {
    display: none;
  }
}

@include breakpoint(medium down) {
  // トリガーとなるボタン
  .c-slidebar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    text-align: center;
    font-size: rem-calc(12);
    z-index: 9999;
    text-decoration: none;
    line-height: 1;
    background: none;
    color: $color-white;
    width: rem-calc(48);
    height: rem-calc(48);
    padding: 0;
    border: none;

    &:active,
    &:hover {
      opacity: 1;
    }

    &__inner{
      display: block;
    }

    // ボーダー
    &__line {
      height: rem-calc(15);
      display: block;
      > span {
        display: block;
        width: rem-calc(19);
        height: rem-calc(3);
        background: $color-secondary;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3px;
        transition: all ease .2s;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    // メニューテキスト
    &__text {
      transition: all ease .2s;
      font-size: 9px;
      letter-spacing: 0.4px;
      @include webfont();
      margin-top: rem-calc(8);
      display: block;

      &.is-close {
        display: none;
      }
    }
  }

  // メニュー自体
  .c-slidebar-menu {
    height: calc(100vh - 48px);
    padding: 0 0 rem-calc(104);
    position: fixed;
    background-color: $color-primary;
    z-index: 9980;
    width: $slidebar-menu-width;
    transform: translate3d(100%, 0px, 0px);
    right: 0;
    -webkit-transition: $slidebar-menu-eaasing;
    transition: $slidebar-menu-eaasing;
    overflow-x: scroll;
    top: 0;
    -webkit-overflow-scrolling: touch;

    &.is-active {
      height: calc(100% - 65px);
    }

    // 上から下へ
    &.is-top-to-bottom {
      transform: translate3d(0px, -100%, 0px);
      width: 100%;
      opacity: 0;
    }


    // メニュー要素（通常）
    &__list{
      width: 100%;
      background: $color-primary;
      li {
        border-bottom: 1px solid $color-secondary;
      }
      a,
      span {
        font-size: rem-calc(15);
        display: block;
        padding: rem-calc(14) rem-calc(16);
        text-decoration: none;
        color: $color-white;
        font-weight: 600;
      }
    }

    // メニュー要素（子）
    &__children {
      width: 100%;
      padding: 0 rem-calc(16);
      display: none;
      background-color: $color-primary;

      li {
        &:last-child {
          border-bottom: none;
        }

        a {
          color: $color-white;
          padding: rem-calc(16) 0;
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }

    // メニュー要素（親）
    &__parent {
      a,
      span {
        position: relative;

        &::after,
        &::before {
          content: "";
          display: block;
          width: rem-calc(2);
          height: rem-calc(18);
          background-color: $color-white;
          position: absolute;
          top: 50%;
          right: rem-calc(24);
          transform: translateY(-50%);
        }

        &::after {
          width: rem-calc(18);
          height: rem-calc(2);
          right: rem-calc(16);
        }
      }

      &.is-open {
        a,
        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &__button {
      margin-top: rem-calc(32);
      max-width: 100% !important;
      background-color: $color-white;
      color: $color-primary;

      &::before {
        content: "\f0e0";
        font-family: "FontAwesome";
        color: $color-primary;
      }
    }
  }

  // メニュー以外をラップする要素
  // メニュー以外をラップする要素
  .c-slidebar-container {
    height: 100%;
    -webkit-transition: $slidebar-menu-eaasing;
    transition: $slidebar-menu-eaasing;

    &::after {
      content: "";
      position: fixed;
      width: 100%;
      height: 100%;
      background: $slidebar-container-bg;
      top: 0;
      left: 0;
      opacity: 0;
      -webkit-transition: $slidebar-menu-eaasing;
      transition: $slidebar-menu-eaasing;
      display: none;
      z-index: 999;
    }
  }

  // 有効時
  .is-slidebar-active {
    //cursor: pointer;

    .c-slidebar-button {
      &__line {
        > span {
          &:nth-child(1) {
            transform: rotate(-45deg) translateY(5px) translateX(-5px);
          }

          &:nth-child(2) {
            transform: rotate(45deg) translateY(1px) translateX(1px);
          }

          &:nth-child(3) {
            display: none;
          }
        }
      }

      &__text {
        &.is-close {
          display: block;
          margin-top: rem-calc(8);
        }

        &.is-open {
          display: none;
        }
      }
    }

    .c-slidebar-container {
      -webkit-transform: translateX(-$slidebar-menu-width);
      transform: translate3d(-$slidebar-menu-width, 0px, 0px);

      &::after {
        content: "";
        opacity: 1;
        position: fixed;
        width: 100%;
        height: 100%;
        background: $slidebar-container-bg;
        display: block;
        top: 0;
        left: 0;
      }

      // 上から下の場合
      &.is-top-to-bottom {
        -webkit-transform: translateX(0px);
        transform: translate3d(0px, 0px, 0px);

        &::after {
          top: 48px;
        }
      }
    }

    .c-slidebar-menu {
      -webkit-transform: translateX(0);
      transform: translate3d(0, 0, 0);

      &.is-top-to-bottom {
        -webkit-transform: translateY(48px);
        transform: translate3d(0, 48px, 0);
        opacity: 1;
      }
    }
  }
}
