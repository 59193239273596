/*
---
name: オファー_通常
category: Layout
---
*/
.l-offer {

  &__blocks {
    display: flex;

    @include breakpoint(small only) {
      display: block;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
    height: 24.3vw;

    @include breakpoint(small only) {
      height: 48.5vw;
      max-height: rem-calc(350);
    }

    .bg-img {
      @include bg-img();
      @include transition();

      &:after {
        @include img-curtain($color-black,0.4);
      }
    }

    &:hover {
      opacity: 1;

      .bg-img {
        transform: scale(1.1);
      }
    }
  }

  &__title {

    &.c-heading.is-xlg {
      margin-bottom: 0;
    }
  }
}
