/*
---
name: ヘッダー
category: Layout
---
*/
@use "sass:math";

.l-header {
  background-color: $color-white;
  width: 100%;
  z-index: 9990;
  position: fixed;
  top: 0;
  left: 0;

  &__logo {
    margin: 0;
    flex-shrink: 0;

    a,
    img {
      display: block;
    }

    a {
      @include font-format(30,0.16,30,900);

      @include breakpoint(1250 down) {
        font-size: rem-calc(24);
      }

      @include breakpoint(small only) {
        font-size: rem-calc(16);
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    padding: 0 rem-calc(70);
    height: rem-calc(100);

    @include breakpoint(1400 down) {
      padding: 0 rem-calc(40);
    }

    @include breakpoint(1250 down) {
      padding: 0 rem-calc(20);
    }

    @include breakpoint(medium down) {
      height: rem-calc(48);
    }

    @include breakpoint(small only) {
      padding: 0 rem-calc(16);
    }
  }

  &__nav {
    margin-left: auto;

    @include breakpoint(medium down) {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      width: 100%;

      li {
        padding: 0 rem-calc(24);

        @include breakpoint(1400 down) {
          padding: 0 rem-calc(16);
        }

        @include breakpoint(1000 down) {
          padding: 0 rem-calc(12);
        }

        &:last-child {
          padding-right: 0;
        }

        &:not(:first-child) {
          border-left: 1px dashed $color-secondary;
        }

        a {
          display: block;
          position: relative;
          @include font-format(15,0.16,24,600);
          transition: all .3s ease;

          @include breakpoint(1250 down) {
            font-size: rem-calc(14);
          }

          @include breakpoint(1000 down) {
            font-size: rem-calc(13);
          }

          //装飾
          &::after {
            content: "";
            display: block;
            width: 0;
            height: rem-calc(5);
            background-color: $color-primary;
            position: absolute;
            bottom: rem-calc(-7);
            left: 50%;
            transform: translateX(-50%) translateY(100%);
            opacity: 0;
            transition: all .3s ease;
          }

          //*hover
          &:hover {
            opacity: 1;
            color: $color-primary;

            &::after {
              width: 100%;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__tel {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: rem-calc(40);
    @include font-format(32,0,32,700);

    @include breakpoint(1400 down) {
      margin-left: rem-calc(24);
    }

    @include breakpoint(1250 down) {
      margin-left: rem-calc(20);
      font-size: rem-calc(24);
    }

    @include breakpoint(1000 down) {
      margin-left: rem-calc(16);
    }

    @include breakpoint(small only) {
      font-size: rem-calc(16);
      margin-left: rem-calc(12);
    }

    &:before {
      content: "";
      width: rem-calc(25);
      height: rem-calc(25);
      background-image: url(../images/icon-tel-secondary.png);
      margin-right: rem-calc(12);
      @include bg-option();
      @include transition();

      @include breakpoint(1250 down) {
        width: rem-calc(20);
        height: rem-calc(20);
      }

      @include breakpoint(small only) {
        width: rem-calc(16);
        height: rem-calc(16);
        margin-right: rem-calc(4);
      }
    }

    &:hover {
      opacity: 1;
      color: $color-primary;

      &:before {
        background-image: url(../images/icon-tel-primary.png);
      }
    }
  }

  &__button {
    margin-left: rem-calc(40);

    @include breakpoint(1400 down) {
      margin-left: rem-calc(24);
    }

    @include breakpoint(1250 down) {
      margin-left: rem-calc(20);
      max-width: rem-calc(170) !important;
    }

    @include breakpoint(1000 down) {
      margin-left: rem-calc(16);
    }

    @include breakpoint(medium down) {
      width: rem-calc(32);
      height: rem-calc(32);
      border-radius: 50%;
    }

    @include breakpoint(small only) {
      margin-left: rem-calc(10);
    }

    span {
      @include breakpoint(medium down) {
        display: none;
      }
    }

    &:before {
      @include breakpoint(medium down) {
        margin-right: 0 !important;
      }
    }
  }
}

// スマホメニューopen時
.is-slidebar-active {
  .l-header {
    position: fixed;
    top: 0;
    left: 0;
  }
}




