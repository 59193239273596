/*
---
name: ボタン_カラー別
category: Buttons
---
*/
.c-button {
  position: relative;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: rem-calc(410);
  text-align: center;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 10px;
  color: $color-white;
  text-decoration: none;
  padding: rem-calc(28);
  @include font-format(20,0.05,24,700);
  transition: all .3s;

  @include breakpoint(small only) {
    font-size: rem-calc(16);
    padding: rem-calc(20);
  }

  // *hover
  &:hover {
    opacity: 1;
    background-color: $color-white;
    color: $color-primary;
  }

  // カラー設定
  //文字と背景反転
  &.is-reverse {
    background-color: $color-white;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  // -> セカンダリカラー
  &.is-secondary {
    background-color: lighten($color-primary, 40);
    color: $color-primary;
    border-color: lighten($color-primary, 40);

    &:hover {
      background-color: $color-white;
      border-color: $color-primary;
    }
  }
}

/*
---
name: ボタン_サイズ別
category: Buttons
---
*/
.c-button {
  // サイズ設定

  // -> 小
  &.is-sm {
    max-width: rem-calc(220);
    padding: rem-calc(12) rem-calc(16);
    @include font-format(15,0.05,20,700);

    @include breakpoint(small only) {
      font-size: rem-calc(13);
    }
  }

  &.is-nav {
    color: $color-primary;
    background: $color-white;
    border: 1px solid $color-primary;
    padding: rem-calc(16) rem-calc(24);
    max-width: 100%;
    height: 100%;
    border-radius: $border-radius;

    @include breakpoint(small only) {
      padding: rem-calc(12) rem-calc(24);
    }

    &:after {
      content: "\f107";
      right: rem-calc(16);
      transform: translateY(-50%);
      font-size: rem-calc(18);
      line-height: 1;

      @include breakpoint(small only) {
        font-size: rem-calc(14);
      }
    }

    &:hover {
      color: $color-gray;
      border-color: $color-gray;
      transform: translateY(6px);
    }
  }

  &.is-arrow-left {

    &:before {
      content: "\f104";
      font-family: "FontAwesome";
      position: absolute;
      right: auto;
      left: rem-calc(10);
    }
  }

  &.is-mail {

     // *アイコン
    &::before {
      content: "\f0e0";
      font-family: "FontAwesome";
      margin-right: rem-calc(20);
      font-size: rem-calc(20);

      @include breakpoint(small only) {
        margin-right: rem-calc(16);
        font-size: rem-calc(16);
      }
    }
  }
}


