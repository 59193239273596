/*
---
name: <table> テーブル
category: Base
---
*/
.c-table {
  width: 100%;
  border-top: 1px solid $color-secondary;

  tbody {
    th,
    td {
      text-align: left;
      border-bottom: 1px solid $color-secondary;
      padding: rem-calc(30);
      vertical-align: top;

      @include breakpoint(small only) {
        padding: rem-calc(16) rem-calc(12);
      }
    }

    th {
      width: rem-calc(196);
      background: $color-accent;

      @include breakpoint(small only) {
        width: rem-calc(96);
      }
    }

    td {
      background: $color-white;
    }
  }
}
