.c-embed {
  a {
    display: block;
    color: inherit;

    &:hover {
      opacity: 0.7 !important;

      .c-embed__image-bg {
        opacity: 0.7;
      }
    }
  }

  font-size: $font-base-size;
  font-family: $font-base-family;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: $font-base-color;
  margin: 0;
  line-height: $font-base-line-height;
  letter-spacing: $font-base-letter-spacing;
  overflow-x: hidden;
  background: #F9F9F9;
  padding: rem-calc(40) rem-calc(40) rem-calc(40) rem-calc(40);
  border: none;
  box-shadow: none;
  @include breakpoint(small only) {
    padding: rem-calc(16) rem-calc(16) rem-calc(16) rem-calc(16);

  }

  &.is-title-only {
    .c-embed__heading {
      margin-bottom: 0;

      a {
        padding: 8px 0;
      }
    }

    .c-embed__inner {
      display: none !important;
    }

  }

  &__inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include breakpoint(small only) {
      display: block;
    }
  }

  &__heading {
    a {
      color: $color-primary;
      text-decoration: none;
      font-weight: bold;
      font-size: rem-calc(18);
      line-height: 1.2;
    }

    margin-bottom: rem-calc(16);
    @include breakpoint(small only) {
      margin-bottom: rem-calc(8);

    }
  }

  &__image {
    width: 30%;
    margin-right: rem-calc(32);

    @include breakpoint(small down) {
      width: 100%;
    }
  }

  &__image-bg {
    width: 100%;
    max-width: rem-calc(240);
    margin: 0 auto;
    @include bg-option();
    background-size: cover;

    &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%;
    }

    @include breakpoint(small down) {
      margin: 0 auto 16px;
    }

  }

  &__content {
    width: 100%;
    line-height: 1.8;

    a {
      color: $font-base-color;
      text-decoration: none;
      //color: inherit;
      &:hover {
        opacity: 0.7 !important;
      }
    }
  }

  .wp-embed-more {
    text-align: right;

    &::after {
      content: " > ";
    }
  }
}


.wp-embedded-content {
  display: block;
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 16px;
  margin-bottom: 16px;
}
