@use "sass:math";

.c-tel-top {
  padding: rem-calc(48);
  background-color: #F9F9F9;
  text-align: center;
  @include breakpoint(small only) {
    padding: rem-calc(24);
  }

  &__text {
    margin-top: rem-calc(6);
    font-size: rem-calc(16);
    line-height: math.div(24, 16)*1;
    letter-spacing: 0.05em;
    @include breakpoint(small only) {
      font-size: rem-calc(12);
      line-height: math.div(20, 12)*1;
    }
  }

  &__title {
    color: $font-base-color;
    font-size: rem-calc(18);
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: math.div(28, 18)*1;
    margin-bottom: rem-calc(14);
    @include breakpoint(small only) {
      font-size: rem-calc(16);
      margin-bottom: rem-calc(8);
    }
  }

  a {
    text-decoration: none;
    color: $color-primary;
    font-size: rem-calc(42);
    letter-spacing: 0;
    line-height: 1;
    pointer-events: none;
    @include breakpoint(small only) {
      font-size: rem-calc(24);
      pointer-events: inherit;
    }

    i {
      margin-right: rem-calc(12);
    }
  }

}
